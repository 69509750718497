(function(w) {
   'use strict';
   
   var loggedInUserSummaryEndpoint = '/api/v1/analytics/user/summary';
   var userInfoSingleton;
   var filteredAiDependencyHosts = [
      /google-analytics\.com$/i
   ];

   var aiTelemetryFilters = function(envelope) {
      var sendItem = true;

      if (w.Microsoft.ApplicationInsights.Telemetry && envelope.name === w.Microsoft.ApplicationInsights.Telemetry.RemoteDependencyData.envelopeType) {
         var ajaxUri = envelope.baseData.target;
         //https://www.ietf.org/rfc/rfc3986.txt (AppendixB)
         var urlParts = ajaxUri.match(RegExp('^(([^:/?#]+):)?(//([^/?#]*))?([^?#]*)(\\?([^#]*))?(#(.*))?'));
         // eslint-disable-next-line no-magic-numbers
         var host = urlParts.length >= 5 ? urlParts[4] : ''; // authority/host is the 4th matchgroup above
         filteredAiDependencyHosts.forEach(function(h) {
            if (h.test(host)) {
               sendItem = false;
            }
         });
      }

      return sendItem;
   };

   var initAppInsights = function() {
      /* eslint-disable */
      // ReSharper disable All
      var appInsights = w.appInsights || (function (e) { function n(e) { t[e] = function () { var n = arguments; t.queue.push(function () { t[e].apply(t, n) }) } } var t = { config: e }; t.initialize = !0; var i = document, a = w; setTimeout(function () { var n = i.createElement("script"); n.src = e.url || "https://az416426.vo.msecnd.net/scripts/b/ai.2.min.js", i.getElementsByTagName("script")[0].parentNode.appendChild(n) }); try { t.cookie = i.cookie } catch (e) { } t.queue = [], t.version = 2; for (var r = ["Event", "PageView", "Exception", "Trace", "DependencyData", "Metric", "PageViewPerformance"]; r.length;)n("track" + r.pop()); n("startTrackPage"), n("stopTrackPage"); var s = "Track" + r[0]; if (n("start" + s), n("stop" + s), n("setAuthenticatedUserContext"), n("clearAuthenticatedUserContext"), n("flush"), !(!0 === e.disableExceptionTracking || e.extensionConfig && e.extensionConfig.ApplicationInsightsAnalytics && !0 === e.extensionConfig.ApplicationInsightsAnalytics.disableExceptionTracking)) { n("_" + (r = "onerror")); var o = a[r]; a[r] = function (e, n, i, a, s) { var c = o && o(e, n, i, a, s); return !0 !== c && t["_" + r]({ message: e, url: n, lineNumber: i, columnNumber: a, error: s }), c }, e.autoExceptionInstrumented = !0 } return t }(
         {
            instrumentationKey: w.analyticsConfig.appInsightsId
         }
      ));

      appInsights.queue.push(function () {
         // dont send AJAX metrics for other trackers
         appInsights.addTelemetryInitializer(aiTelemetryFilters);
      });
      // ReSharper restore All
      /* eslint-enable */

      w.appInsights = appInsights;
   };

   var initGoogleAnalytics = function() {
      /* eslint-disable */
      (function (i, s, o, g, r, a, m) {
         i.GoogleAnalyticsObject = r; i[r] = i[r] || function () {
            (i[r].q = i[r].q || []).push(arguments);
         }, i[r].l = 1 * new Date(); a = s.createElement(o),
            m = s.getElementsByTagName(o)[0]; a.async = 1; a.src = g;
         m.parentNode.insertBefore(a, m);
      })(w, document, 'script', '//www.google-analytics.com/analytics.js', 'ga');
      /* eslint-enable */

      w.ga('create', w.analyticsConfig.googleAnalyticsKey, {
         cookieDomain: w.analyticsConfig.googleAnalyticsDomainName,
         allowLinker: true
      });
   };

   var initGoogleTagManager = function() {
      /* eslint-disable */
      (function(w,d,s,l,i){
         w[l]=w[l]||[];w[l].push({'gtm.start': new Date().getTime(),event:'gtm.js'});
         var f=d.getElementsByTagName(s)[0],j=d.createElement(s),dl=l!='dataLayer'?'&l='+l:'';
         j.async=true;j.src='https://ssgtm.techsmith.com/gtm.js?id='+i+dl;f.parentNode.insertBefore(j,f);
      })(w,document,'script','dataLayer', w.analyticsConfig.googleTagManagerContainerId);

      var ifrm = document.createElement('iframe');
      ifrm.setAttribute('src', '//www.googletagmanager.com/ns.html?id=' + w.analyticsConfig.googleTagManagerContainerId);
      ifrm.style.width = '0';
      ifrm.style.height = '0';
      ifrm.style.display = 'none';
      ifrm.style.visibility = 'hidden';

      var noscript = document.createElement('noscript');
      noscript.id = 'GTMiframe';
      noscript.appendChild(ifrm);

      document.body.insertBefore(noscript, document.body.firstChild);
      /* eslint-enable */
   };

   var loggedInUserSummary = function() {
      userInfoSingleton = userInfoSingleton ||
         fetch(loggedInUserSummaryEndpoint).then(function(userResponse) {
            return userResponse.json();
         }).then(function(data) {
            data.isLoggedIn = true;
            return data;
         }).catch(function() {
            return {
               isLoggedIn: false
            };
         });
      return userInfoSingleton;
   };

   var recordGaPageView = function() {
      return loggedInUserSummary().then(function(loggedInUserInfo) {
         w.ga('set', 'signed_in', loggedInUserInfo.isLoggedIn);
         w.ga('set', 'registered', loggedInUserInfo.isLoggedIn);
         w.ga('set', 'tsc_id', loggedInUserInfo.techSmithId);
         w.ga('send', 'pageview', {page: w.location.pathname + w.location.search + w.location.hash});
      });
   };

   var recordAppInsightsPageView = function() {
      w.appInsights && w.appInsights.trackPageView();
   };

   initGoogleAnalytics();
   initAppInsights();
   if (w.analyticsConfig.shouldLoadGoogleTagManager) {
      initGoogleTagManager();
   }

   recordGaPageView();
   recordAppInsightsPageView();
}(window));
